































































































import { FormHelper, Toast } from '@/mixins'
import { Game } from '@/api'
import { GameModule } from '@/store/modules'
import { Component, Mixins } from 'vue-property-decorator'

enum SwapType {
  ENABLE,
  DISABLE,
}

@Component({
  components: {
    CharacterSwapCard: () => import('@/components/Character/CharacterSwapCard.vue'),
  }
})
export default class CharacterSwappingModal extends Mixins(FormHelper, Toast) {
  @GameModule.State characters
  @GameModule.Mutation updateCharacterActive

  isOpen = false
  showSwapModal = false
  characterIdToDisable = null
  characterIdToEnable = null
  availableCharacters = [] as any
  swapError = ''
  tutorialKeys = Array.from({ length: 3 }, (_, i) => `CHARACTER_SWAPPING_RULE${i + 1}`)

  async mounted() {
    this.$root.$on("openCharacterSwappingModal", async () => {
      this.isOpen = true
    })

    this.availableCharacters = this.characters.map(e => ({
      value: e.id,
      text: e.name,
      isActive: e.isActive
    }))
  }

  async updateCharacterState(swapType: SwapType, characterId: string | number) {
    const { success, errors } = swapType === SwapType.ENABLE
      ? await Game.enableCharacter(characterId)
      : await Game.disableCharacter(characterId)

    if (!success) {
      this.swapError = errors[0].code

      setTimeout(() => {
        this.swapError = ''
      }, 10000)

      return
    }

    this.updateCharacterActive(characterId)
  }

  async swap(characterIdToEnable, characterIdToDisable) {
    const { success, errors } = await Game.swapCharacter(characterIdToEnable, characterIdToDisable)

    if (!success) {
      this.swapError = errors[0].code

      setTimeout(() => {
        this.swapError = ''
      }, 10000)

      return
    }

    this.updateCharacterActive(characterIdToEnable)
    this.updateCharacterActive(characterIdToDisable)
  }
}
